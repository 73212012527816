<template>
  <el-dialog
    title="预览"
    :visible="dialogTableVisible"
    modal-append-to-body
    append-to-body
    :width="previewInfo.type === 2 ? '800px' : '600px'"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeModal"
  >
    <video v-if="previewInfo.type === 2" class="preview__video bg-black" controls :src="previewInfo.url"></video>
    <audio v-if="previewInfo.type === 1" class="preview__audio" controls :src="previewInfo.url"></audio>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      dialogTableVisible: false
    }
  },

  computed: {
    ...mapGetters(['previewInfo'])
  },

  watch: {
    previewInfo: {
      immediate: true,
      deep: true,
      handler: function (v) {
        if (v && v.show && v.url) {
          this.dialogTableVisible = true
        } else {
          this.dialogTableVisible = false
        }
      }
    }
  },

  mounted() {},

  methods: {
    closeModal() {
      this.$store.commit('SET_KEY', { key: 'previewInfo', value: {} })
    }
  }
}
</script>

<style lang="scss" scoped>
.preview__video {
  width: 100%;
  height: 600px;
}

.preview__audio {
  width: 100%;
}
</style>
