import axios from '@/utils/axios'

const taskModule = {
  state: {
    recentTaskList: JSON.parse(sessionStorage.getItem('bread_front_recentTaskList') || '[]') // 最近访问的历史任务列表，最多展示六个
  },

  getters: {
    recentTaskList: state => state.recentTaskList.reverse().slice(0, 6)
  },

  mutations: {
    SET_TASK_KEY(state, data) {
      state[data.key] = data.value
    },
    // 新推入任务
    PUSH_TASK_ITEM(state, data) {
      const list = state.recentTaskList.filter(i => i.id !== data.id)
      list.unshift(data)
      state.recentTaskList = list.slice(0, 6)
      sessionStorage.setItem('bread_front_recentTaskList', JSON.stringify(state.recentTaskList))
    },
    // 刷新最近任务列表
    RESET_TASK_LIST(state, data) {
      state.recentTaskList = data
      sessionStorage.setItem('bread_front_recentTaskList', JSON.stringify(state.recentTaskList))
    }
  },

  actions: {
    // 刷新任务列表
    async refreshRecentList(context) {
      try {
        const res = await axios.get('/v1/tasks', {
          params: { page: 1, pageSize: context.state.recentTaskList.length, taskIds: context.state.recentTaskList.map(i => i.id).join(',') }
        })
        context.commit('RESET_TASK_LIST', res.data || [])
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default taskModule
