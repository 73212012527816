import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'tailwindcss/tailwind.css'
import '@/style/reset.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import axios from '@/utils/axios'
import '@/utils/filters'
import '@/utils/index'
import mixpanel from 'mixpanel-browser'

Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$mixpanel = mixpanel

// 添加全局导航守卫
router.beforeEach((to, from, next) => {
  const token = store.state.userInfo.token
  const whitePath = ['/oauth/callback/wechat', '/app/index']
  if (!token && !whitePath.includes(to.path)) {
    next({
      path: '/app/index',
      query: { redirect: to.fullPath } // 将目标路由信息传递给登录页面
    })
  } else {
    next()
  }
  next()
})

mixpanel.init('3931e646c0fe0494892ad489cec4ab74', { debug: true, track_pageview: true, persistence: 'localStorage' })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
