import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/home/index.vue'
import Layout from '@/layout/index.vue'
import EditLayout from '@/editLayout/index.vue'

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/app/index',
    name: 'Index',
    component: () => import('@/views/index/index.vue'),
    meta: {
      name: '官网首页'
    }
  },
  {
    path: '/oauth/callback/wechat',
    name: 'Atuh',
    component: () => import('@/views/auth/index.vue'),
    meta: {
      name: '认证'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Layout,
    redirect: '/home/index',
    children: [
      {
        path: '/home/index',
        name: 'HomeView',
        component: HomeView,
        meta: {
          name: '平台首页'
        }
      },
      {
        path: '/localUpload/index',
        name: 'LocalUploadIndex',
        component: () => import('@/views/localUpload/index.vue'),
        meta: {
          name: '素材库'
        }
      },
      {
        path: '/taskBar/index',
        name: 'TaskBarIndex',
        component: () => import('@/views/taskBar/index.vue'),
        meta: {
          name: '任务栏'
        }
      }
    ]
  },
  {
    path: '/create',
    name: 'Create',
    component: EditLayout,
    redirect: '/create/face',
    children: [
      {
        path: '/create/face',
        name: 'Face',
        component: () => import('@/views/face/index.vue'),
        meta: {
          name: '换脸'
        }
      },
      {
        path: '/create/subtitle',
        name: 'Subtitle',
        component: () => import('@/views/subtitle/index.vue'),
        meta: {
          name: '清除字幕'
        }
      },
      {
        path: '/create/lipSync',
        name: 'LipSync',
        component: () => import('@/views/lipSync/index.vue'),
        meta: {
          name: '对口型'
        }
      },
      {
        path: '/create/success',
        name: 'CreateSuccess',
        component: () => import('@/views/createSuccess/index.vue'),
        meta: {
          name: '生成完成'
        }
      },
      {
        path: '/create/editPage',
        name: 'CreateEdit',
        component: () => import('@/views/editPage/index.vue'),
        meta: {
          name: '编辑'
        }
      },
      {
        path: '/create/seniorEdit',
        name: 'CreateSeniorEdit',
        component: () => import('@/views/seniorEdit/index.vue'),
        meta: {
          name: '高级编辑'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
