import Vue from 'vue'
import Vuex from 'vuex'
import foldersModule from './modules/folders'
import taskModule from './modules/task'

Vue.use(Vuex)

const userString = localStorage.getItem('breed_front_userInfo')

export default new Vuex.Store({
  state: {
    showLoginModal: false, // 是否显示登录弹窗
    showBuyModal: false, // 是否显示购买弹窗
    // 是否需要手机号
    loginModalInfo: {
      needRegPhoneNumber: false,
      openid: ''
    },
    userInfo: userString
      ? JSON.parse(userString)
      : {
          id: '',
          token: ''
        },
    previewInfo: {}, // 预览
    timerList: []
  },

  getters: {
    showLoginModal: state => state.showLoginModal,
    showBuyModal: state => state.showBuyModal,
    userInfo: state => state.userInfo,
    previewInfo: state => state.previewInfo,
    timerList: state => state.timerList,
    loginModalInfo: state => state.loginModalInfo
  },

  mutations: {
    SET_KEY(state, data) {
      state[data.key] = data.value
    },
    SET_USER(state, value) {
      state.userInfo = { ...state.userInfo, ...value }
      localStorage.setItem('breed_front_userInfo', JSON.stringify(state.userInfo))
    },
    CLEAR_STORAGE() {
      localStorage.clear('breed_front_userInfo')
    },
    LOGOUT_APP(state) {
      state.userInfo = {}
      localStorage.removeItem('breed_front_userInfo')
    }
  },

  actions: {},

  modules: {
    foldersModule,
    taskModule
  }
})
