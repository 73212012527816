<template>
  <div class="layout__menu hs">
    <img v-if="!recentTaskList.length" class="cant__edit btnHover cant" src="@/assets/cant_edit_img.png" alt="" />

    <template v-else>
      <img class="add__icon btnHover" src="@/assets/edit_add_icon.svg" alt="" @click="addNewTask" />
      <div :class="['file__origin btnHover', `${taskId}` === `${item.id}` && 'active']" v-for="item in showTaskList" :key="item.id" @click="changeTask(item)">
        <el-image v-if="item.fileType === 'video'" class="w-full h-full rounded" :src="item.attributes.thumbnail && item.attributes.thumbnail[0]" fit="fill">
          <div slot="error" class="w-full h-full flex items-center justify-center bg-gray-100">
            <i class="el-icon-picture-outline text-xl"></i>
          </div>
        </el-image>
        <el-image v-else class="w-full h-full rounded" :src="item.attributes.rawUrls[0]"></el-image>
        <img v-if="item.attributes.status === 'completed'" class="cover__icon" src="@/assets/video_play_white.svg" alt="" />
        <img v-else class="cover__icon" src="@/assets/loading_cover.svg" alt="" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},

  data() {
    return {
      taskId: '',
      channel: 1
    }
  },

  computed: {
    ...mapGetters(['recentTaskList']),

    // 当前任务
    currentTask() {
      if (this.taskId) {
        const item = this.recentTaskList.find(i => `${i.id}` === `${this.taskId}`)
        return item || { attributes: {} }
      }
      return { attributes: {} }
    },

    showTaskList() {
      return this.recentTaskList.map(i => {
        return {
          ...i,
          fileType: this.$filterFileTypeByUrl(i.attributes.rawUrls[0]).key
        }
      })
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: function () {
        this.taskId = this.$route.query.taskId
        if (['/create/seniorEdit', '/create/editPage'].includes(this.$route.path) && (!this.recentTaskList.length || !this.taskId)) {
          this.$router.push('/')
        }
      }
    }
  },

  mounted() {
    this.channel = +this.$route.query.channel || 1
  },

  methods: {
    changeTask(item) {
      if (`${item.id}` !== `${this.taskId}`) {
        this.$router.replace(`/create/editPage?taskId=${item.id}`)
      }
    },

    // 创建新任务
    addNewTask() {
      if (this.currentTask.id) {
        const type = this.currentTask.attributes.type
        const url = {
          face_swap: '/create/face',
          remove_subtitles: '/create/subtitle',
          lip_sync: '/create/lipSync'
        }[type]
        this.$router.push({
          path: url,
          query: {
            channel: this.channel
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout__menu {
  width: 180px;
  height: 100%;
  flex-shrink: 0;
  background-color: #e8f4ff;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;
  padding: 40px 0 20px 0;
  overflow-y: auto;

  .cant__edit {
    display: block;
    margin: auto;
    width: 120px;
    height: 120px;
    flex-shrink: 0;
  }

  .add__icon {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .file__origin {
    position: relative;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    overflow: hidden;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;

    &.active {
      width: 120px;
      height: 120px;
    }
  }

  .cover__icon {
    width: 56px;
    height: 56px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
</style>
