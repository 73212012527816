<template>
  <div class="home__page">
    <div class="changing__head">
      <div class="head__change bg1" @click="$router.push('/create/face?channel=1')">
        <span class="head__Uname">换脸</span>
      </div>
      <div class="head__change bg2" @click="$router.push('/create/subtitle?channel=1')">
        <!-- <div class="head__change bg2"> -->
        <span class="head__Uname">去字幕</span>
      </div>
      <div class="head__change bg3" @click="$router.push('/create/lipSync?channel=1')">
        <!-- <div class="head__change bg3"> -->
        <span class="head__Uname">对口型</span>
      </div>
    </div>

    <div class="task__title">
      <div class="task__Uname">任务快看</div>
    </div>
    <PaginationTwo :taskList="taskList" @refresh="loadTaskList" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PaginationTwo from './components/paginationTwo/index.vue'

var timer = null

export default {
  components: { PaginationTwo },

  data() {
    return {
      selectedOption: 1,
      loading: false,
      taskList: []
    }
  },

  computed: {
    ...mapGetters(['timerList'])
  },

  beforeDestroy() {
    if (timer) clearTimeout(timer)
  },

  mounted() {
    this.loadTaskList()
  },

  methods: {
    changeContent(option) {
      this.selectedOption = option // 切换内容
    },

    // 查询任务列表
    async loadTaskList() {
      if (timer) clearTimeout(timer)
      try {
        this.loading = true
        const res = await this.$axios.get('/v1/tasks', { params: { page: 1, pageSize: 1000 } })
        this.taskList = res.data.map(i => {
          return {
            ...i,
            fileType: this.$filterFileTypeByUrl(i.attributes.rawUrls[0]).key
          }
        })

        timer = setTimeout(() => {
          this.loadTaskList()
        }, 5000)
        this.$store.commit('SET_KEY', { key: 'timerList', value: [...this.timerList, { type: 1, timer }] })
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.home__page {
  width: 100%;
  height: 100%;
  padding: 62px 40px 0 40px;
  box-sizing: border-box;
  overflow-y: auto;

  .changing__head {
    max-width: 1130px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    margin-bottom: 30px;

    .head__change {
      height: 191px;
      flex-shrink: 0;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;

      .head__Uname {
        color: #fff;
        font-family: 'PingFang SC';
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
      }

      &:hover {
        .head__Uname {
          opacity: 0.8;
        }
      }

      &.bg1 {
        background-image: url('@/image/home_Rectangle.png');
      }

      &.bg2 {
        background-image: url('@/image/home_letter.png');
      }

      // &.bg2 {
      //   opacity: 0.4;
      //   cursor: not-allowed;
      //   background-image: url('@/image/home_letter.png');

      //   &:hover {
      //     .head__Uname {
      //       opacity: 1;
      //     }
      //   }
      // }

      &.bg3 {
        // opacity: 0.4;
        // cursor: not-allowed;
        background-image: url('@/image/home_mouth.png');

        // &:hover {
        //   .head__Uname {
        //     opacity: 1;
        //   }
        // }
      }
    }
  }

  .task__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    box-sizing: border-box;

    .task__Uname {
      color: #000;
      font-family: 'PingFang SC';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }

    .task__image {
      width: 25px;
      height: 25px;
      flex-shrink: 0;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
