<template>
  <el-dialog :title="modalTitle" class="upload__popUps" :visible.sync="dialogVisible" width="454px" :before-close="closeModal">
    <div class="medium__content overflow-y-scroll">
      <div v-for="item in fileUploadProgress" :key="item.uid">
        <div class="medium__vpn" v-if="item.percentage !== 100 && item.percentage !== -1">
          <div class="medium__hong">{{ item.name || '' }}</div>
          <el-progress class="progress" :percentage="item.percentage"></el-progress>
          <img class="w-5 h-5 btnHover" @click="cancelUPload(item)" src="@/image/material__vector.png" alt="" />
        </div>
        <div class="loaded" v-else-if="item.percentage === -1">
          <div class="loaded__already hd">{{ item.name || '' }}</div>
          <div class="loaded__error flex items-center justify-end">
            <i class="el-icon-warning-outline mr-1"></i>
            <span>上传失败</span>
          </div>
        </div>
        <div class="loaded" v-else>
          <div class="loaded__already hd">{{ item.name || '' }}</div>
          <div class="loaded__superior">已上传成功</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="removeHistory">清除历史记录</el-button>
      <el-button type="primary" @click="closeModal">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters(['showUploading', 'fileUploadProgress', 'uploadSuccessFiles']),

    modalTitle() {
      if (!this.fileUploadProgress.length) return '暂无文件上传'
      if (this.uploadSuccessFiles.length === this.fileUploadProgress.length) return '文件均上传完成'
      return `已完成 ${this.uploadSuccessFiles.length}/${this.fileUploadProgress.length}`
    }
  },

  watch: {
    showUploading: {
      immediate: true,
      handler: function (v) {
        this.dialogVisible = v
      }
    }
  },

  methods: {
    closeModal() {
      this.$store.commit('SET_FOLDER_KEY', { key: 'showUploading', value: false })
    },

    // 清理历史记录
    removeHistory() {
      this.$store.dispatch('updateFileProgress', { type: 'clear' })
    },

    // 取消上传
    cancelUPload(item) {
      if (item.cancelToken) {
        item.cancelToken.cancel('取消上传')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload__popUps {
  ::v-deep {
    .el-dialog__header {
      padding: 16px 0 20px 20px;

      .el-dialog__title {
        color: #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      .el-dialog__headerbtn {
        .el-icon-close {
          color: #000;
        }
      }
    }
    .el-dialog__body {
      padding: 0 26px 0 20px;
    }
    .el-dialog__footer {
      padding: 16px 28px 19px 21px;

      .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .el-button {
          color: #222;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }

        .el-button--primary {
          color: #fff;
        }
      }
    }
  }

  .medium__content {
    width: 408px;
    height: 370px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background: #fff;
    scrollbar-width: none;

    .medium__vpn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 17px 14px 12px;
      border-bottom: 1px solid #eaeaea;
      .medium__vpn:nth-last-of-type(1) {
        background-color: yellow;
      }

      .medium__hong {
        color: #333;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        width: 90px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .loaded {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 14px 14px 12px;
      border-bottom: 1px solid #eaeaea;

      .loaded__already {
        flex: 1;
        color: #333;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .loaded__error {
        color: #f22;
        flex-shrink: 0;
        width: 70px;
        text-align: right;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .loaded__superior {
        flex-shrink: 0;
        width: 70px;
        text-align: right;
        color: #00c65b;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .progress {
    width: 242px;

    ::v-deep {
      .el-progress-bar {
        .element.style {
          color: #333;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
