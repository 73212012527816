<template>
  <div class="layout__top--bar">
    <img @click="$router.push('/')" class="logo__img btnHover h-full" src="@/assets/app_logo.png" alt="" />
    <div class="bar__text">
      <span class="btnHover" @click="gotoPage">{{ channelText }}</span> | <span>{{ wayText }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},

  data() {
    return {
      channel: 1,
      taskId: ''
    }
  },

  computed: {
    ...mapGetters(['recentTaskList']),

    wayText() {
      return this.currentTask
        ? this.$filterTaskNameByType(this.currentTask.attributes.type)
        : {
            '/create/face': '换脸',
            '/create/subtitle': '清除字幕',
            '/create/lipSync': '对口型'
          }[this.$route.path] || ''
    },

    channelText() {
      return (
        {
          1: '首页',
          2: '任务'
        }[this.channel] || ''
      )
    },

    // 当前编辑的task信息
    currentTask() {
      if (!this.taskId || !this.recentTaskList.length) return null
      const item = this.recentTaskList.find(i => `${i.id}` === `${this.taskId}`)
      return item
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: function () {
        this.taskId = this.$route.query.taskId || ''
      }
    }
  },

  mounted() {
    this.channel = +this.$route.query.channel || 1
  },

  methods: {
    gotoPage() {
      if (this.channel === 2) {
        this.$router.push('/taskBar/index')
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout__top--bar {
  width: 100%;
  height: 80px;
  background-color: #1890ff;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 40px;
  padding-left: 70px;

  .logo__img {
    height: 50px;
  }

  .bar__text {
    color: #fff;
    font-family: 'PingFang SC';
    font-size: 24px;
    font-weight: 600;
    margin-left: 66px;
  }
}
</style>
