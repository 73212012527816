import Vue from 'vue'

// 根据任务类型反显任务类型名称
const filterTaskNameByType = type => {
  return (
    {
      face_swap: '换脸',
      remove_subtitles: '去字幕',
      lip_sync: '对口型'
    }[type] || '未知'
  )
}

// 根据任务状态反显任务类型名称
const filterTaskProgressByStatus = ({ status, progress }) => {
  if (!status) return ''
  if (status === 'failed') return '任务失败'
  if (status === 'completed') return '已完成'
  if (['processing', 'ready'].includes(status)) return `生成中 ... ${progress || 0}%`
  return '异常'
}

// 根据链接获取文件后缀
const filterGetFileSuffix = path => {
  if (!path) return ''
  let url = path.split('?')[0]
  const types = url.split('.')
  return types.length ? types[types.length - 1].toUpperCase() : ''
}

// 根据文件大小转换为单位
const filterFileSize = size => {
  if (!size) return '0KB'
  let fileKb = size / 1024
  if (fileKb <= 1024) return `${fileKb.toFixed(2)}KB`
  return `${(fileKb / 1024).toFixed(2)}MB`
}

// 根据文件链接，返回文件类型
const filterFileTypeByUrl = url => {
  if (!url) return { key: '', title: '' }
  const path = url.split('?').splice(0)[0].toUpperCase()
  const type = path.split('.').splice(-1)[0].toUpperCase()
  if (['MP3'].includes(type)) return { key: 'audio', title: '音频' }
  if (['MP4', 'WMV', 'MOV', 'AVI'].includes(type)) return { key: 'video', title: '视频' }
  if (['JPG', 'PNG', 'JPEG'].includes(type)) return { key: 'image', title: '图片' }
  return { key: '', title: '' }
}

// 根据文件路径返回文件名称
const filterFileNameByUrl = url => {
  if (!url) return ''
  const path = decodeURIComponent(url.split('?').splice(0)[0])
  const name = path.split('/').splice(-1)[0]
  return name
}

// decode字符串
const filterFileNameByDecode = str => {
  if (!str || typeof str !== 'string') return str
  const name = decodeURIComponent(str)
  return name
}

// 注册所有过滤器
const filters = {
  filterTaskNameByType,
  filterTaskProgressByStatus,
  filterGetFileSuffix,
  filterFileSize,
  filterFileTypeByUrl,
  filterFileNameByUrl,
  filterFileNameByDecode
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$filterTaskNameByType = filterTaskNameByType
Vue.prototype.$filterFileTypeByUrl = filterFileTypeByUrl
Vue.prototype.$filterFileSize = filterFileSize
Vue.prototype.$filterTaskNameByType = filterTaskNameByType

export default filters
