import qs from 'qs'
import download from 'downloadjs'
import Vue from 'vue'
import * as faceapi from 'face-api.js'

faceapi.nets.ssdMobilenetv1.loadFromUri('/models')

export const getUrlCode = url => {
  const path = url || location.href
  const str = path.split('#')[0].split('?')[1]
  if (str) {
    const query = qs.parse(str)
    if (query.code) {
      return query.code
    }
  }
  return ''
}

/**
 * 格式化时间
 */
export function df(value, fmt = 'YYYY-mm-dd HH:MM:SS') {
  let ret
  let date = value
  if (!value) return ''
  if (typeof value === 'string' || typeof value === 'number') date = new Date(value)
  const opt = {
    'Y+': date.getFullYear().toString(),
    'm+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'H+': date.getHours().toString(),
    'M+': date.getMinutes().toString(),
    'S+': date.getSeconds().toString()
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

// 计算两个时间之间的差异
export function calculateTimeDifference(startTime, endTime) {
  // 将时间字符串转换为 Date 对象
  const startDate = new Date(startTime)
  const endDate = new Date(endTime)

  // 计算时间差（以毫秒为单位）
  const differenceInMillis = endDate - startDate

  // 计算天、小时、分钟和秒
  const days = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24))
  const hours = Math.floor((differenceInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((differenceInMillis % (1000 * 60)) / 1000)

  let str = ''

  if (days > 0) str += `${days}天`
  if (days > 0 || hours > 0) str += `${hours}小时`
  if (days > 0 || hours > 0 || minutes > 0) str += `${minutes}分钟`
  if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) str += `${seconds}秒`

  return str
}

// 将时长转换为 分钟:秒数 结构，入参单位毫秒
export function formatDuration(ms) {
  // 将毫秒转换为秒
  const totalSeconds = Math.floor(ms / 1000)

  // 计算分钟和秒
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60

  // 格式化分钟和秒数，确保两位数显示
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  // 返回格式化后的时间字符串
  return `${formattedMinutes}:${formattedSeconds}`
}

// 下载文件
export const downloadFile = url => {
  if (!url) return false
  if (process.env.VUE_APP_ENV === 'development') {
    const path = url.replace('https://breed-1326551551.cos.ap-guangzhou.myqcloud.com', process.env.VUE_APP_IMAGE_BASE)
    download(path)
  } else {
    download(url)
  }
}

// 校验图片是否存在人脸
export const detectFace = file => {
  return new Promise(resolve => {
    if (!file) return resolve(true)
    const reader = new FileReader()
    reader.onload = e => {
      const imageSrc = e.target.result
      // 创建一个新的 image 元素
      const img = new Image()
      img.src = imageSrc

      // 等待图像加载完成
      img.onload = async () => {
        // 检测人脸
        try {
          const detections = await faceapi.detectAllFaces(img)
          resolve(detections.length === 1)
        } catch (error) {
          resolve(true)
        }
      }
    }
    reader.readAsDataURL(file)
  })
}

Vue.prototype.$downloadFile = downloadFile
Vue.prototype.$df = df
Vue.prototype.$cf = calculateTimeDifference
Vue.prototype.$fd = formatDuration
Vue.prototype.$detectFace = detectFace
