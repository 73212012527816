<template>
  <div id="app">
    <router-view />

    <!-- 文件上传进度弹窗 -->
    <UploadingModal />

    <!-- 文件预览 -->
    <PreviewModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadingModal from '@/components/uploadingModal/index.vue'
import PreviewModal from '@/components/previewModal/index.vue'

var timer = null

export default {
  components: { UploadingModal, PreviewModal },

  data() {
    return {}
  },

  computed: {
    ...mapGetters(['recentTaskList', 'timerList'])
  },

  created() {
    window.addEventListener('beforeunload', () => {
      this.clearTimer()
    })
  },

  mounted() {
    const userString = localStorage.getItem('breed_front_userInfo')
    if (userString) {
      const uesr = JSON.parse(userString)
      this.$mixpanel.identify(uesr.id)
    }
  },

  watch: {
    recentTaskList: {
      deep: true,
      immediate: true,
      handler: function (v) {
        this.clearTimer()
        if (v.length) {
          if (timer) clearInterval(timer)
          timer = setInterval(() => {
            if (this.$route.path === '/app/index') {
              this.clearTimer()
            } else {
              this.$store.dispatch('refreshRecentList')
            }
          }, 5000)
          this.$store.commit('SET_KEY', { key: 'timerList', value: [...this.timerList, { type: 2, timer }] })
        }
      }
    },

    $route: {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.$route.path === '/app/index') {
          this.clearTimer()
          this.$store.commit('SET_USER', { token: '' })
        }
      }
    }
  },

  methods: {
    clearTimer() {
      this.timerList.forEach(k => {
        try {
          if (k.type === 1) {
            clearTimeout(k.timer)
          } else if (k.type === 2) {
            clearInterval(k.timer)
          }
        } catch (error) {
          console.log(error)
        }
      })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
