<template>
  <div class="left__menu--box">
    <div class="breed__bre flex items-center justify-center btnHover" @click="$router.push('/')">
      <img class="breed__image" src="@/assets/app_logo.png" mode="widthFix" />
    </div>
    <div>
      <div v-for="item in menuList" :key="item.key" class="left__frontPage" :class="{ active: activePage === item.path }" @click="setActivePage(item)">
        <img class="frontPage__image" src="@/image/menu_icon.png" alt="" />
        <span class="frontPage__uname">{{ item.title }}</span>
      </div>
    </div>

    <div class="user__info btnHover">
      <img class="myShop__img" src="@/image/user_attr.png" alt="" />
      <el-dropdown :hide-on-click="false" @command="handleCommand">
        <div class="myShop__butt">
          <template v-if="showPhone">
            <div class="myShop__say hd">{{ showPhone }}</div>
            <!-- <div class="myShop__flowerTime hd">已花35分钟/200分钟</div> -->
          </template>
          <div v-else class="myShop__say">登录</div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <LoginModal />
    <BuyModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginModal from '@/components/loginModel/index.vue'
import BuyModal from '@/components/buyModal/index.vue'

export default {
  components: { LoginModal, BuyModal },

  data() {
    return {
      menuList: [
        {
          key: 1,
          path: '/home/index',
          title: '首页'
        },
        {
          key: 2,
          path: '/localUpload/index',
          title: '素材库'
        },
        {
          key: 3,
          path: '/taskBar/index',
          title: '任务栏'
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['userInfo']),

    activePage() {
      return this.$route.path || ''
    },

    showPhone() {
      if (this.userInfo.phone) {
        return this.userInfo.phone.slice(0, 3) + '***' + this.userInfo.phone.slice(-2)
      }
      return ''
    }
  },

  methods: {
    setActivePage({ path }) {
      this.$router.push(path)
    },

    // 显示弹窗
    showUserModal() {
      if (this.showPhone) {
        this.$store.commit('SET_KEY', { key: 'showBuyModal', value: true })
      } else {
        this.$store.commit('SET_KEY', { key: 'showLoginModal', value: true })
      }
    },

    handleCommand(type) {
      if (type === 'a') {
        this.$confirm('此操作将退出登录状态, 是否继续?', '退出登录', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$store.commit('LOGOUT_APP')
            this.$router.push('/app/index')
          })
          .catch(() => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.left__menu--box {
  width: $menuWidth;
  height: 100%;
  flex-shrink: 0;
  background-color: #1890ff;
  padding: 0 10px;
  overflow: hidden;
  position: relative;

  .breed__bre {
    width: 100%;
    padding: 30px 16px 82px 16px;
    box-sizing: border-box;

    .breed__image {
      width: 60px;
      flex-shrink: 0;
    }
  }

  .left__frontPage {
    padding: 15px 0 15px 28px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    flex-shrink: 0;
    margin-bottom: 25px;
    padding-left: 28px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;

    &.active {
      box-sizing: border-box;
      background: rgb(225, 225, 225, 0.2);
    }

    &:hover {
      opacity: 0.8;
    }

    .frontPage__image {
      width: 29px;
      height: 29px;
      flex-shrink: 0;
    }

    .frontPage__uname {
      color: #fff;
      font-family: 'PingFang SC';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      padding-left: 16px;
      box-sizing: border-box;
    }
  }

  .user__info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80px;
    background-color: #1890ff;
    overflow: hidden;
    padding-bottom: 40px;

    .myShop__img {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    .myShop__butt {
      // width: 100%;
      // flex: 1;
      // overflow: hidden;
      padding-left: 9px;

      .myShop__say {
        color: #fff;
        font-family: 'PingFang SC';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .myShop__flowerTime {
        color: #fff;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }

  .paymentReminder {
    ::v-deep {
      .el-dialog {
        border-radius: 10px !important;
        .el-dialog__header {
          padding: 0px;
          .el-dialog__headerbtn {
            .el-dialog__close {
              color: #000000;
            }
          }
        }

        .el-dialog__body {
          padding: 50px 20px 20px;
        }
      }
    }

    .success__dia {
      display: flex;
      flex-direction: column;

      .success__layer {
        display: flex;
        align-items: center;
        padding-bottom: 4px;

        .success__image {
          width: 32px;
          height: 32px;
        }

        .success__become {
          color: #333;
          font-family: 'PingFang SC';
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          padding-left: 16px;
        }
      }

      .success__trace {
        color: #888;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        padding-left: 48px;
        padding-bottom: 24px;
      }

      .closure__bu {
        padding: 11px 32px;
        border-radius: 4px;
        background: #5a66ff;
        color: #fff;
        text-align: center;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      .closure__butt {
        width: 80px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #ddd;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333;
        text-align: center;
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;

        &.renew {
          background: #5a66ff;
          color: #fff;
          border: none;
        }
      }
    }
  }
}
</style>
