import axios from '@/utils/axios'

const foldersModule = {
  state: {
    folderList: [], // 素材库文件夹树
    fileUploadProgress: [], // 文件上传进度列表
    showUploading: false, // 是否显示上传进度弹窗
    moduleTypes: [] // 模型类型
  },

  getters: {
    folderList: state => state.folderList,
    // 文件上传列表
    fileUploadProgress: state => state.fileUploadProgress,
    // 正在上传中的文件
    uploadingFiles: state => state.fileUploadProgress.filter(i => i.percentage >= 0 && i.percentage < 100),
    // 已经上传完成的文件
    uploadSuccessFiles: state => state.fileUploadProgress.filter(i => i.percentage === 100),
    // 文件上传最大数值，最多并行上传10个文件
    uploadLimit: state => {
      const loading = state.fileUploadProgress.filter(i => i.percentage >= 0 && i.percentage < 100)
      return state.fileUploadProgress.length + (10 - loading.length)
    },
    showUploading: state => state.showUploading,
    // 换脸类型模型
    faceModuleTypes: state => state.moduleTypes.filter(i => i.type === 'model'),
    // 对嘴型类型模型
    speakerModuleTypes: state => state.moduleTypes.filter(i => i.type === 'speaker'),
    // 个人更目录
    selfRootFolder: state => {
      const rootFolder = state.folderList.find(i => i.type === 'root')
      return rootFolder
    },
    // 个人专有文件夹根目录文件夹
    selfFolders: state => {
      const rootFolder = state.folderList.find(i => i.type === 'root')
      if (rootFolder && rootFolder.subFolders && rootFolder.subFolders.length) {
        return [...rootFolder.subFolders]
      }
      return []
    },
    // 分享根目录文件夹
    shareRootFolder: state => {
      const shareFolder = state.folderList.filter(i => i.type === 'shared_space')
      return shareFolder
    }
  },

  mutations: {
    SET_FOLDER_KEY(state, data) {
      state[data.key] = data.value
    }
  },

  actions: {
    // 获取素材库文件夹树
    async initFolderList(context) {
      try {
        const res = await axios.get('/v1/folders')
        context.commit('SET_FOLDER_KEY', { key: 'folderList', value: res.data || [] })
      } catch (error) {
        console.log(error)
      }
    },

    // 更新文件上传进度
    async updateFileProgress(context, { type, file = {} }) {
      let list = [...context.state.fileUploadProgress]
      const index = list.findIndex(i => i.uid === file.uid)
      if (type === 'add') {
        list.push({ ...file })
      }
      // 清理已经上传完成的记录
      else if (type === 'clear') {
        list = list.filter(i => i.percentage !== 100)
      }
      // 更新进度
      else if (type === 'progress' && index !== -1) {
        list.splice(index, 1, { ...file })
      }
      // 取消上传
      else if (type === 'delete' && index !== -1) {
        list.splice(index, 1)
      }
      // 未查找到的均未新增
      context.commit('SET_FOLDER_KEY', { key: 'fileUploadProgress', value: list })
    },

    // 获取模型类型
    async getModulesType(context) {
      try {
        const res = await axios.get('/v1/dicts')
        context.commit('SET_FOLDER_KEY', { key: 'moduleTypes', value: res.data || [] })
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default foldersModule
