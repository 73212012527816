<template>
  <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" class="buy__modal myShopWeo" width="660px" :before-close="closeModal">
    <div v-if="showType === 1" class="myShop__box">
      <div class="myShop__head">
        <div class="information__neo">
          <img class="information__image" src="@/image/group__avatar.png" alt="" />
          <div class="information__two">
            <div class="information__wo">我的小铺 <span class="information__cine">医药</span></div>
            <div class="information__Uname">
              当前可用视频时长 <span class="information__number">100</span> 分钟；存储空间 <span class="information__number">40G/100G</span>
            </div>
          </div>
        </div>
      </div>
      <div class="package__part">
        <div class="package__selection">套餐选择</div>
        <div class="pay__for">
          <div class="pay__title" v-for="item in buyList" :key="item.id">
            <div class="pay__money">{{ item.money }}</div>
            <div class="pay__duration">{{ item.duration }}</div>
          </div>
        </div>
        <div class="flex items-center justify-center flex-col">
          <div @click="getToggleView(2)" class="weChatPay__butt">微信支付</div>
          <div class="read__part">
            <span class="read__icon"></span>
            <img class="read__text" src="@/assets/read_status_text.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showType === 2" class="weChatPayCode__popUps">
      <div class="microScan__box">
        <img class="microScan__return" @click="getToggleView(1)" src="@/image/material__left.png" alt="" />
        <span class="microScan__Uname">微信支付</span>
      </div>
      <img class="scanIt__image" src="@/image/material__scanIt.png" alt="" />
      <div class="twoButtons">
        <div class="twoButtons__completed mr-6">刷新页面</div>
        <div @click="innerVisible = true">
          <div class="twoButtons__completed refresh">已完成支付</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialogVisible: false,
      innerVisible: false,
      showType: 1,
      completed: 1,
      closeToggle: 1,
      buyList: [
        { id: 1, money: '￥100', duration: '1000分钟' },
        { id: 2, money: '￥300', duration: '3500分钟' },
        { id: 3, money: '￥450', duration: '5000分钟' },
        { id: 4, money: '￥700', duration: '8000分钟' }
      ]
    }
  },

  computed: {
    ...mapGetters(['showBuyModal'])
  },

  watch: {
    showBuyModal: {
      immediate: true,
      handler: function (v) {
        this.dialogVisible = v
        if (v) {
          this.getToggleView(1)
        }
      }
    }
  },

  methods: {
    getToggleView(e) {
      this.showType = e
    },

    closeModal() {
      this.getToggleView(1)
      this.$store.commit('SET_KEY', { key: 'showBuyModal', value: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.buy__modal {
  ::v-deep {
    .el-dialog {
      border-radius: 10px;
      .el-dialog__header {
        padding: 0px;
        .el-dialog__headerbtn {
          .el-dialog__close {
            color: #ffffff;
          }
        }
      }
      .el-dialog__body {
        padding: 0px;
      }
    }

    .myShop__box {
      display: flex;
      flex-direction: column;
      .myShop__head {
        width: 660px;
        height: 407px;
        flex-shrink: 0;
        background-image: url('@/image/rectangle__ba 2977.png');
        background-size: 100% 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .information__neo {
          display: flex;
          align-items: center;
          padding-bottom: 8px;

          .information__image {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
          }

          .information__two {
            display: flex;
            flex-direction: column;
            padding-left: 13px;

            .information__wo {
              color: #fff;
              font-family: 'PingFang SC';
              font-size: 20px;
              font-style: normal;
              font-weight: 400;
              padding-bottom: 8px;
              display: flex;
              align-items: center;

              .information__cine {
                width: 38px;
                height: 21px;
                flex-shrink: 0;
                border-radius: 4px;
                border: 1px solid #f8f8f8;
                color: #fff;
                font-family: 'PingFang SC';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
              }
            }

            .information__Uname {
              color: #fff;
              font-family: PoetsenOne;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;

              .information__number {
                font-weight: 600;
              }
            }
          }
        }
      }

      .package__part {
        padding: 17px 0px 48px 0px;

        .package__selection {
          color: #333;
          font-family: PoetsenOne;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          padding-bottom: 16px;
          padding-left: 102px;
        }

        .pay__for {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 21px;

          .pay__title {
            width: 108px;
            height: 126px;
            flex-shrink: 0;
            border-radius: 10px;
            background: rgba(232, 244, 255, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
            &:last-of-type {
              margin-right: 0px;
            }

            .pay__money {
              color: #333;
              font-family: PoetsenOne;
              font-size: 22px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              padding-bottom: 5px;
            }

            .pay__duration {
              color: #333;
              font-family: 'PingFang SC';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              padding-left: 8px;
            }
          }
        }

        .weChatPay__butt {
          width: 463px;
          height: 44px;
          flex-shrink: 0;
          border-radius: 10px;
          background: #1890ff;
          color: #fff;
          font-family: 'PingFang SC';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .read__part {
          padding-top: 13px;
          display: flex;
          align-items: center;

          .read__icon {
            width: 12px;
            height: 12px;
            flex-shrink: 0;
            border-radius: 4px;
            background: #d9d9d9;
            cursor: pointer;
          }

          .read__text {
            width: 247px;
            height: 14px;
            margin-left: 6px;
          }

          .read__agree {
            display: flex;
            align-items: flex-end;
            color: #333;
            font-family: PoetsenOne;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            padding-left: 4px;

            .el__bre {
              height: 10px;
              width: 40px;
            }
          }
        }
      }
    }
  }
}

.myShopWeo {
  ::v-deep {
    .el-dialog {
      border-radius: 10px !important;
      .el-dialog__header {
        padding: 0;
        .el-dialog__headerbtn {
          .el-dialog__close {
            color: #000000;
          }
        }
      }
    }
  }

  .weChatPayCode__popUps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 106px 0 43px 0;

    .microScan__box {
      display: flex;
      align-items: center;
      position: absolute;
      top: 46px;
      left: 36px;

      .microScan__return {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        cursor: pointer;
      }

      .microScan__Uname {
        color: #000;
        font-family: 'PingFang SC';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        padding-left: 10px;
      }
    }

    .scanIt__image {
      width: 565px;
      height: 357px;
      flex-shrink: 0;
      margin-bottom: 24px;
    }

    .twoButtons {
      display: flex;
      align-items: center;

      .twoButtons__completed {
        width: 160px;
        padding: 22px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid #ddd;
        background: #fff;
        color: #333;
        text-align: center;
        font-family: 'PingFang SC';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;

        &.refresh {
          background: #1890ff;
          color: #fff;
          border: none;
        }
      }
    }
  }
}
</style>
