<template>
  <div class="lay__edit">
    <TopBar />
    <div class="layout__main">
      <LeftMenu />
      <div class="page__scroll">
        <div class="page__con hs">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from './components/leftMenu/index.vue'
import TopBar from './components/topBar/index.vue'

export default {
  components: { LeftMenu, TopBar },

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.lay__edit {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .layout__main {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;

    .page__scroll {
      flex: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-x: auto;

      .page__con {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        min-width: $contentMinWidth;
      }
    }
  }
}
</style>
