<template>
  <div class="lay__out">
    <LeftMenu />
    <div class="page__scroll">
      <div class="page__con">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/layout/components/leftMenu/index.vue'

export default {
  components: { LeftMenu },

  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.lay__out {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  .page__scroll {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-x: auto;

    .page__con {
      width: 100%;
      height: 100%;
      min-width: $contentMinWidth;
    }
  }
}
</style>
