<template>
  <div class="paginationTwo__page">
    <div class="changing__sum">
      <div class="changing__neo">换脸</div>
      <template v-if="faceList.length">
        <div class="business__taste" v-for="item in faceList" :key="item.id" @click="gotoPage(item)">
          <div class="business__ter">
            <el-image
              v-if="item.fileType === 'video'"
              class="w-full h-full rounded"
              :src="item.attributes.thumbnail && item.attributes.thumbnail[0]"
              fit="fill"
            >
              <div slot="error" class="w-full h-full flex items-center justify-center bg-gray-100">
                <i class="el-icon-picture-outline text-xl"></i>
              </div>
            </el-image>
            <el-image v-else class="w-full h-full rounded" :src="item.attributes.rawUrls[0]">
              <div slot="error" class="w-full h-full flex items-center justify-center bg-gray-100">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div class="business__tit">
            <div class="business__say hd btnHover">{{ item.attributes.name || '' }}</div>
            <div v-if="item.attributes.status === 'processing'" class="business__used hd">
              {{ item.attributes.progress }}%，已用时{{ $cf(item.attributes.createdAt, item.attributes.finishedAt || Date.now()) }}
            </div>
            <div v-else class="business__used hd">
              {{ item.attributes | filterTaskProgressByStatus }}，用时{{ $cf(item.attributes.createdAt, item.attributes.finishedAt || Date.now()) }}
            </div>
          </div>
          <div class="delete" @click.stop="deleteTask(item)">
            <img class="delete__remove" src="@/image/delete_bucket.png" alt="" />
          </div>
        </div>
      </template>
      <div v-else class="text-gray-400 text-sm">还没有换脸任务，快去创建吧</div>
    </div>
    <div class="changing__sum">
      <div class="changing__neo">去字幕</div>
      <template v-if="subtitleList.length">
        <div class="business__taste" v-for="item in subtitleList" :key="item.id" @click="gotoPage(item)">
          <div class="business__ter">
            <el-image
              v-if="item.fileType === 'video'"
              class="w-full h-full rounded"
              :src="item.attributes.thumbnail && item.attributes.thumbnail[0]"
              fit="fill"
            >
              <div slot="error" class="w-full h-full flex items-center justify-center bg-gray-100">
                <i class="el-icon-picture-outline text-xl"></i>
              </div>
            </el-image>
            <el-image v-else class="w-full h-full rounded" :src="item.attributes.rawUrls[0]">
              <div slot="error" class="w-full h-full flex items-center justify-center bg-gray-100">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div class="business__tit">
            <div class="business__say hd btnHover">{{ item.attributes.name || '' }}</div>
            <div v-if="item.attributes.status === 'processing'" class="business__used hd">
              {{ item.attributes.progress }}%，已用时{{ $cf(item.attributes.createdAt, item.attributes.finishedAt || Date.now()) }}
            </div>
            <div v-else class="business__used hd">
              {{ item.attributes | filterTaskProgressByStatus }}，用时{{ $cf(item.attributes.createdAt, item.attributes.finishedAt || Date.now()) }}
            </div>
          </div>
          <div class="delete" @click.stop="deleteTask(item)">
            <img class="delete__remove" src="@/image/delete_bucket.png" alt="" />
          </div>
        </div>
      </template>
      <div v-else class="text-gray-400 text-sm">还没有去字幕任务，快去创建吧</div>
    </div>
    <div class="changing__sum">
      <div class="changing__neo">对口型</div>
      <template v-if="lipSyncList.length">
        <div class="business__taste" v-for="item in lipSyncList" :key="item.id" @click="gotoPage(item)">
          <div class="business__ter">
            <el-image
              v-if="item.fileType === 'video'"
              class="w-full h-full rounded"
              :src="item.attributes.thumbnail && item.attributes.thumbnail[0]"
              fit="fill"
            >
              <div slot="error" class="w-full h-full flex items-center justify-center bg-gray-100">
                <i class="el-icon-picture-outline text-xl"></i>
              </div>
            </el-image>
            <el-image v-else class="w-full h-full rounded" :src="item.attributes.rawUrls[0]">
              <div slot="error" class="w-full h-full flex items-center justify-center bg-gray-100">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div class="business__tit">
            <div class="business__say hd btnHover">{{ item.attributes.name || '' }}</div>
            <div v-if="item.attributes.status === 'processing'" class="business__used hd">
              {{ item.attributes.progress }}%，已用时{{ $cf(item.attributes.createdAt, item.attributes.finishedAt || Date.now()) }}
            </div>
            <div v-else class="business__used hd">
              {{ item.attributes | filterTaskProgressByStatus }}，用时{{ $cf(item.attributes.createdAt, item.attributes.finishedAt || Date.now()) }}
            </div>
          </div>
          <div class="delete" @click.stop="deleteTask(item)">
            <img class="delete__remove" src="@/image/delete_bucket.png" alt="" />
          </div>
        </div>
      </template>
      <div v-else class="text-gray-400 text-sm">还没有对口型任务，快去创建吧</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    taskList: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {}
  },

  computed: {
    faceList() {
      return this.taskList.filter(i => i.attributes.type === 'face_swap').slice(0, 10)
    },
    subtitleList() {
      return this.taskList.filter(i => i.attributes.type === 'remove_subtitles').slice(0, 10)
    },
    lipSyncList() {
      return this.taskList.filter(i => i.attributes.type === 'lip_sync').slice(0, 10)
    }
  },

  watch: {},

  mounted() {},

  methods: {
    // 删除任务
    async deleteTask(item) {
      this.$confirm('此操作将永久删除任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            const data = { taskIds: [item.id] }
            await this.$axios.delete(`/v1/tasks`, { data })
            this.$message.success(`操作成功`)
            this.$emit('refresh')
          } catch ({ message }) {
            this.$message.error(message || '服务异常，请稍后重试')
          }
        })
        .catch(() => {})
    },

    gotoPage(item) {
      this.$store.commit('PUSH_TASK_ITEM', item)
      this.$router.push({ path: '/create/editPage', query: { taskId: item.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.paginationTwo__page {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 30px;

  .changing__sum {
    width: 100%;
    overflow: hidden;

    .changing__neo {
      padding-bottom: 10px;
      box-sizing: border-box;
      box-sizing: border-box;
      color: #000;
      font-family: 'PingFang SC';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border-bottom: 1px solid #666;
      margin-bottom: 20px;
    }

    .business__taste {
      width: 100%;
      display: flex;
      margin-bottom: 20px;
      padding: 8px;
      padding-right: 0;
      overflow: hidden;

      .business__ter {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        position: relative;

        .business__avatar {
          width: 100%;
          height: 100%;
        }

        .business__become {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          pointer-events: none;
          display: flex;
          align-items: center;
          justify-content: center;

          .business__number {
            color: #fff;
            font-family: 'PingFang SC';
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          &.visibility {
            display: none;
          }
        }
      }

      .business__tit {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 19px;
        box-sizing: border-box;
        color: #000;
        overflow: hidden;
        justify-content: center;

        .business__say {
          font-family: 'PingFang SC';
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 6px;
          box-sizing: border-box;
          line-height: normal;
        }

        .business__used {
          font-family: 'PingFang SC';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .delete {
        width: 64px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        display: none;
        flex-shrink: 0;

        .delete__remove {
          width: 20px;
          height: 20px;
        }
      }

      &:hover {
        background: #1890ff;
        cursor: pointer;
        user-select: none;

        .business__tit {
          color: #fff;
        }

        .delete {
          display: flex;
        }
      }
    }
  }
}
</style>
